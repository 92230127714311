/**
 * Created by fkurzawa on 05.02.18.
 */
import { createActions, handleActions } from 'redux-actions';

const { addLoader, removeLoader } = createActions('ADD_LOADER', 'REMOVE_LOADER');
const reducer = handleActions(
    {
        [addLoader]: (state, {payload}) => ([
            ...state,
            payload
        ]),
        [removeLoader]: (state, {payload}) => ([
            ...state.filter(loaderId => loaderId !== payload)
        ])
    },
    []
);

export { addLoader, removeLoader };
export default reducer;