import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withTranslate } from "../../../translate";

const SeparatorH2 = ({ text }) => {
  return (
    <SeparatorListItem>
      <Text>{text}</Text>
    </SeparatorListItem>
  );
};

SeparatorH2.propTypes = {
  text: PropTypes.string.isRequired
};

const Text = withTranslate(styled.h2`
  color: ${({ theme }) => theme.menuSeparatorColor || theme.text.primaryColor};
  word-wrap: break-word;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875em;
  margin-bottom: 0;
`);

const SeparatorListItem = styled.li`
  background: ${({ theme }) =>
    theme.menuSeparatorBackground || theme.defaultBackground};
  position: relative;
  z-index: 2;
  padding: 15px;
  min-height: 60px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12), 0 2px 3px rgba(0, 0, 0, 0.24);
  cursor: default;
`;

export { SeparatorH2 };
