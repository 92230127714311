import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import EmblemImage from "./EmblemImage";

const Emblem = ({ url }) => (
  <div id="bip-emblem">
    {url ? (
      <Link
        href={url}
        title={`Przejdź do strony: ${url}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <EmblemImage />
      </Link>
    ) : (
      <EmblemImage />
    )}
  </div>
);

const Link = styled.a`
  color: ${({ theme }) => theme.text.primaryColor};
  &:hover {
    color: ${({ theme }) => theme.text.secondaryColor};
  }
  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

Emblem.propTypes = {
  url: PropTypes.string
};

const mapStateToProps = ({ context }) => ({
  url: context.logoHref
});

export { Emblem };
export default connect(mapStateToProps)(Emblem);
