/**
 * Created by fkurzawa on 15.03.18.
 */
import contrast from './contrastTheme';
import grey from './greyTheme';
import primary from './primaryTheme';
import przedborz from './przedborzTheme';
import pks from './pksTheme';
import szamotuly from './szamotulyTheme';
import whiteGrey from './whiteGreyTheme';

const themes = {
    contrast,
    gray: grey,
    primary,
    przedborz,
    pks,
    szamotuly,
    whiteGray: whiteGrey
};

export default themes;