/**
 * Created by fkurzawa on 29.01.18.
 */
import axios from "axios";
import ApiConfig from "./api.config";

const apiUrl = `${ApiConfig.getEntryPoint()}`;

export const getArticle = articleId => {
  return axios
    .get(`${apiUrl}articles/${articleId}`)
    .then(response => response.data);
};

export const getLatestArticles = (friendlyUrl = "default") => {
  return axios
    .get(`${ApiConfig.getEntryPoint()}contexts/${friendlyUrl}/latest-articles`)
    .then(response => response.data);
};

export const getArticleRegistries = (articleId, limit = 10, offset = 0) => {
  return axios
    .get(`${apiUrl}articles/${articleId}/registries`, {
      params: {
        limit,
        offset
      }
    })
    .then(response => response.data);
};

export const getArticleVersions = (articleId, limit = 10, offset = 0) => {
  return axios
    .get(`${apiUrl}articles/${articleId}/versions`, {
      params: {
        limit,
        offset
      }
    })
    .then(response => response.data);
};

export const postArticleComment = (articleId, data) =>
  axios.post(`${apiUrl}articles/${articleId}/comments`, data);

export const getArticleZipAttachmentsUrl = articleId =>
  `${apiUrl}articles/${articleId}/zip-attachments`;

export const getHistoricalArticleZipAttachmentsUrl = versionId =>
  `${apiUrl}article-versions/${versionId}/zip-attachments`;

export const getArticleXmlUrl = articleId =>
  `${apiUrl}articles/${articleId}/xml`;

export const getArticlePdfUrl = articleId =>
  `${apiUrl}articles/${articleId}/pdf`;

export const postArticleForward = (articleId, data) =>
  axios.post(`${apiUrl}articles/${articleId}/forward`, data);
