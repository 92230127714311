/**
 * Created by fkurzawa on 05.02.18.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Translate from './../translate';

const Loader = ({ isLoading }) =>
    isLoading ?
        <Overlay>
            <Container>
                <Spinner />
                <Text>
                    <Translate>Trwa ładowanie danych</Translate>...
                </Text>
            </Container>
        </Overlay> :
        null;

Loader.propTypes = {
    isLoading: PropTypes.bool
};

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1100;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
`;

const Container = styled.div`
    z-index: auto;
    padding: 1.5rem 1rem;
    border-radius: .25rem;
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.12), 0 12px 15px rgba(0, 0, 0, 0.24);
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.defaultBackground};
`;

const Spinner = styled.span.attrs({ className: "fa fa-spinner fa-spin" })`
    && {
        font-size: 2.25em;
        color: ${({ theme }) => theme.text.primaryColor};
    }
`;

const Text = styled.div`
    font-size: 1.25em;
    padding: 0 1rem;
    color:${({ theme }) => theme.text.primaryColor};
`;

const mapStateToProps = ({ loader }) => ({
    isLoading: loader.length > 0
});

export default connect(mapStateToProps)(Loader);