import "core-js/fn/array/find";
import "core-js/fn/array/includes";
import "core-js/fn/array/from";
import "core-js/fn/object/assign";
import "core-js/fn/string/includes";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StoreContext } from "redux-react-hook";
import App from "./App";
import store from "./redux/store";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StoreContext.Provider>,
  document.getElementById("root")
);
unregisterServiceWorker();
