import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  getFontSize,
  getPadding,
  getBoxShadow,
  getColor,
  getBackground,
  getHoverBackground,
  getHoverBorderColor
} from "./utils";

const Button = styled.button`
  && {
    text-align: center;
    font-size: ${getFontSize};
    padding: ${getPadding};
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 1px;
    box-shadow: ${getBoxShadow};
    color: ${getColor};
    background: ${getBackground};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover:not(:disabled) {
      background: ${getHoverBackground};
      border-color: ${getHoverBorderColor};
    }

    &:active:not(:disabled) {
      opacity: 0.6;
    }

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }

    &:focus-within:not(:active) {
      outline: ${({ theme }) => theme.focusOutline};
    }
  }
`;

Button.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "default",
    "strongPrimary",
    "strongSecondary"
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  flat: PropTypes.oneOf(["true", "false"]),
  contrast: PropTypes.oneOf(["true", "false"])
};

const mapStateToProps = ({ accessibility }) => ({
  contrast: accessibility.isContrast === true ? "true" : "false"
});

export default connect(mapStateToProps)(Button);
