import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { ExternalLinkButton, LinkButton } from "../../../buttons";
import LanguagesButton from "./components/LanguagesButton";
import AccessibilityMenu from "./components/AccessibilityMenu";
import { getRssUrl } from "../../../../api/contexts";

const Tools = ({ friendlyUrl, helpArticleUrl, deafArticleUrl }) => {
  const rssUrl = getRssUrl(friendlyUrl);
  return (
    <Container>
      <LanguagesButton />
      <ExternalLinkButton
        href={rssUrl}
        id="rss"
        target="_blank"
        title="Rss"
        aria-label="Rss"
        color="secondary"
        type="button"
      >
        <span className="fa fa-rss" />
      </ExternalLinkButton>
      {helpArticleUrl && (
        <LinkButton
          to={`/${helpArticleUrl}`}
          title="Pomoc"
          aria-label="Pomoc"
          color="secondary"
          type="button"
          id="help-article"
        >
          <span className="fa fa-question-circle" />
        </LinkButton>
      )}
      {deafArticleUrl && (
        <LinkButton
          to={`/${deafArticleUrl}`}
          title="Informacja dla osób niesłyszących"
          aria-label="Informacja dla osób niesłyszących"
          color="secondary"
          type="button"
          id="deaf-article"
        >
          <span className="fa fa-deaf" />
        </LinkButton>
      )}
      <AccessibilityMenu />
    </Container>
  );
};

Tools.propTypes = {
  friendlyUrl: PropTypes.string.isRequired,
  helpArticleUrl: PropTypes.string,
  deadArticleUrl: PropTypes.string
};

const mapStateToProps = ({ context }) => ({
  friendlyUrl: context.contextFriendlyUrl,
  helpArticleUrl: context.helpArticleUrl,
  deafArticleUrl: context.deafArticleUrl
});

const Container = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export default connect(mapStateToProps)(Tools);
