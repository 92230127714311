const getFontSize = ({ size = "medium" }) => sizes[size].fontSize;

const getPadding = ({ size = "medium" }) => sizes[size].padding;

const getBoxShadow = ({ flat = "false", contrast = "false" }) =>
  flat === "true" && contrast === "false"
    ? "none"
    : "0 1px 4px rgba(0, 0, 0, .6)";

const getColor = ({
  theme,
  color = "default",
  flat = "false",
  contrast = "false"
}) => {
  return contrast === "false" && flat === "true" && color !== "default"
    ? theme.buttons[color].background
    : theme.buttons[color].color;
};

const getBackground = ({
  theme,
  color = "default",
  flat = "false",
  contrast = "false"
}) =>
  contrast === "false" && flat === "true"
    ? "transparent"
    : theme.buttons[color].background;

const getHoverBackground = ({
  theme,
  color = "default",
  flat = "false",
  contrast = "false"
}) =>
  contrast === "false" && flat === "true"
    ? "transparent"
    : theme.buttons[color].hoverBackground;

const getHoverBorderColor = ({
  theme,
  color = "default",
  flat = "false",
  contrast = "false"
}) =>
  contrast === "false" && flat === "true"
    ? color === "default"
      ? theme.buttons[color].color
      : theme.buttons[color].background
    : "transparent";

const sizes = {
  small: {
    padding: ".25rem .5rem",
    fontSize: ".75em"
  },
  medium: {
    padding: ".375rem .75rem",
    fontSize: ".875em"
  },
  large: {
    padding: ".5rem 1rem",
    fontSize: "1em"
  }
};

export {
  getFontSize,
  getPadding,
  getBoxShadow,
  getColor,
  getBackground,
  getHoverBackground,
  getHoverBorderColor
};
