import SEARCH_URLS from "./../../constants/search_urls";

import { getAllByUrl } from "./../../api/urls";

import { fetchContextSucceeded } from "./../modules/context";
import { changeLanguage } from "./../modules/language";
import {
  fetchMenuSucceeded,
  fetchAdditionalMenuSucceeded
} from "./../modules/menu";

import { getEnabledMenuElements } from "./../../utils/menu";
import { prepareUrl } from "./prepareUrl";

const getInitialState = path => async dispatch => {
  const url = prepareUrl(path);
  const initialData = await getAllByUrl(url);
  const { context, resource, menu } = initialData;
  const contextFriendlyUrl = resource.context || "default";
  const searchUrl = createSearchUrl(
    context.urlType,
    resource.lang,
    contextFriendlyUrl
  );
  const enhancedContext = {
    ...context,
    contextFriendlyUrl,
    searchUrl
  };
  dispatch(fetchContextSucceeded(enhancedContext));
  dispatch(fetchMenuSucceeded(menu || []));
  const additionalMenuElements = getEnabledMenuElements(
    resource.lang,
    enhancedContext
  );
  dispatch(fetchAdditionalMenuSucceeded(additionalMenuElements));
  dispatch(changeLanguage(resource.lang));
};

const createSearchUrl = (urlType, langCode, friendlyUrl = "default") => {
  const separator = urlType === "full" ? "/" : ",";
  const isNotDefaultContext = friendlyUrl !== "default";
  const context = `${isNotDefaultContext ? friendlyUrl : ""}`;
  const lang = /pl_\w+/.test(langCode)
    ? ""
    : `${isNotDefaultContext ? separator : ""}${
        urlType === "full" ? langCode : langCode.split("_")[0]
      }`;
  const hasContextOrLang = context !== "" || lang !== "";
  return `${context + lang}${hasContextOrLang ? separator : ""}${
    urlType === "friendly" ? SEARCH_URLS.FRIENDLY : SEARCH_URLS.FULL
  }`;
};

export { getInitialState };
