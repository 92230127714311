import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Aside } from "./../aside";
import Container from "./../container";
import { Footer } from "./../footer";
import { AppHeader } from "./../header";
import SkipLinks from "./../skip-links";
import Toolbar from "./../toolbar";

const Layout = ({ children, declarationOfAvailabilityUrl }) => (
  <DefaultStyleWrapper>
    <SkipLinks declarationOfAvailabilityUrl={declarationOfAvailabilityUrl} />
    <Toolbar />
    <AppHeader />
    <Content>
      <Aside />
      <Main id="skip-link-main">{children}</Main>
    </Content>

    <Footer />
  </DefaultStyleWrapper>
);

Layout.propTypes = {
  children: PropTypes.node,
  declarationOfAvailabilityUrl: PropTypes.string
};

const DefaultStyleWrapper = styled.div`
  background: ${({ theme }) =>
    theme.layoutBackground || theme.defaultBackground};
  color: ${({ theme }) => theme.text.primaryColor};
`;

const Content = styled(Container)`
  background: ${({ theme }) => theme.contentBackground};
  display: flex;
  flex-direction: column;

  @media print {
    max-width: 100%;
    margin-left: 30pt;
    margin-right: 30pt;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const Main = styled.main`
  width: 100%;

  @media screen and (min-width: 992px) {
    flex-grow: 1;
    max-width: calc(100% - 250px);
    padding-left: 1rem;
  }
`;

export default Layout;
