/**
 * Created by fkurzawa on 25.01.18.
 */
import { combineReducers } from 'redux';
import accessibility from './modules/accessibility';
import authentication from './modules/authentication';
import breadcrumbs from './modules/breadcrumbs';
import context from './modules/context';
import documentTitle from './modules/document-title';
import language from './modules/language';
import loader from './modules/loader';
import menu from './modules/menu';

const reducer = combineReducers({
    accessibility,
    authentication,
    breadcrumbs,
    context,
    menu,
    language,
    loader,
    documentTitle
});

export default reducer;