import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import { compose, withProps } from "recompose";
import { Loader, PrivateRoute, PublicRoute } from "./components";

const AlcoholSellFeeCalculator = Loadable({
  loader: () => import("./../../containers/alcohol-sell-fee-calculator"),
  loading: Loader
});
const Article = Loadable({
  loader: () => import("./../../containers/article"),
  loading: Loader
});
const ArticlesList = Loadable({
  loader: () => import("./../../containers/articles-list"),
  loading: Loader
});
const CalculatorsList = Loadable({
  loader: () => import("./../../containers/calculators-list"),
  loading: Loader
});
const ContactForm = Loadable({
  loader: () => import("./../../containers/contact-form"),
  loading: Loader
});
const ContentRegistry = Loadable({
  loader: () => import("./../../containers/content-registry"),
  loading: Loader
});
const Editors = Loadable({
  loader: () => import("./../../containers/editors/Editors"),
  loading: Loader
});
const Declarations = Loadable({
  loader: () => import("./../../containers/declarations/Declarations"),
  loading: Loader
});
const EzdCaseStatus = Loadable({
  loader: () => import("./../../containers/ezd-case-status/EzdCaseStatus"),
  loading: Loader
});
const EzdRegistriesList = Loadable({
  loader: () =>
    import("./../../containers/ezd-registries-list/EzdRegistriesList"),
  loading: Loader
});
const EzdRegistry = Loadable({
  loader: () => import("./../../containers/ezd-registry/EzdRegistry"),
  loading: Loader
});
const GreenAreaAndTreeRemovalCalculator = Loadable({
  loader: () =>
    import(
      "./../../containers/green-area-and-tree-removal-calculator/GreenAreaAndTreeRemovalCalculator"
    ),
  loading: Loader
});
const Newsletter = Loadable({
  loader: () => import("./../../containers/newsletter/Newsletter"),
  loading: Loader
});
const NoContentPage = Loadable({
  loader: () => import("./../../containers/no-content-page/NoContentPage"),
  loading: Loader
});
const NoStartArticlePage = Loadable({
  loader: () => import("../../containers/article/NoStartArticlePage"),
  loading: Loader
});
const OccupationOfRoadwayCalculator = Loadable({
  loader: () =>
    import(
      "./../../containers/occupation-of-roadway-calculator/OccupationOfRoadwayCalculator"
    ),
  loading: Loader
});
const RemindPassword = Loadable({
  loader: () => import("./../../containers/remind-password"),
  loading: Loader
});
const SalaryCalculator = Loadable({
  loader: () => import("./../../containers/salary-calculator/SalaryCalculator"),
  loading: Loader
});
const Search = Loadable({
  loader: () => import("./../../containers/search/Search"),
  loading: Loader
});
const SignIn = Loadable({
  loader: () => import("./../../containers/sign-in"),
  loading: Loader
});
const SignUp = Loadable({
  loader: () => import("./../../containers/sign-up"),
  loading: Loader
});
const SiteMap = Loadable({
  loader: () => import("./../../containers/site-map/SiteMap"),
  loading: Loader
});
const Survey = Loadable({
  loader: () => import("./../../containers/survey/Survey"),
  loading: Loader
});
const SurveysList = Loadable({
  loader: () => import("./../../containers/surveys-list/SurveysList"),
  loading: Loader
});
const UserPanel = Loadable({
  loader: () => import("./../../containers/user-panel"),
  loading: Loader
});

const FileNotFound = Loadable({
  loader: () => import("../../containers/fileNotFound"),
  loading: Loader
});

const Routes = ({
  homePath,
  redirectUrl,
  showNoContentRoute,
  userLogInEnabled,
  startArticleId,
  location
}) => {
  const fromPath = location.pathname === "/" ? location.pathname : homePath;

  return (
    <Switch>
      {homePath !== redirectUrl && (
        <Redirect exact from={fromPath} to={redirectUrl} />
      )}
      {!startArticleId && (
        <Route exact path={redirectUrl} component={NoStartArticlePage} />
      )}
      <Route
        path="/(.*)?/Article/:method(get|getVersion)/id,:id.html"
        component={Article}
      />
      <Route path="/(.*,)?:method(a|v),:id,(.*)" component={Article} />
      <Route
        path="/(.*)?/Article/:method(index|getOutdated)?/id,:id.html"
        component={ArticlesList}
      />
      <Route path="/(.*,)?:method(m|o),:id,(.*)" component={ArticlesList} />
      <Route path="/(.*)?/Search" component={Search} />
      <Route path="/(.*,)?e,search.html" component={Search} />
      <Route path="/(.*)?/Editors" component={Editors} />
      <Route path="/(.*,)?e,editors.html" component={Editors} />
      <Route path="/(.*)?/Declarations" component={Declarations} />
      <Route path="/(.*,)?e,deklaracja.html" component={Declarations} />
      <Route path="/(.*)?/ContentRegistry" component={ContentRegistry} />
      <Route
        path="/(.*,)?e,contentRegistry,getList.html"
        component={ContentRegistry}
      />
      <Route path="/(.*)?/EzdCaseStatus" component={EzdCaseStatus} />
      <Route path="/(.*,)?e,ezdCaseStatus.html" component={EzdCaseStatus} />
      <Route
        path="/(.*)?/Newsletter/index(/hash,)?:hash(.*)?.html"
        component={Newsletter}
      />
      <Route path="/(.*,)?e,newsletter.html" component={Newsletter} />
      <Route path="/(.*)?/SiteMap" component={SiteMap} />
      <Route path="/(.*,)?e,mapa-strony.html" component={SiteMap} />
      <Route
        path="/(.*)?/Registry/(get|show)/id,:id.html"
        component={EzdRegistry}
      />
      <Route path="/(.*,)?e,registry,(get|show).html" component={EzdRegistry} />
      <Route path="/(.*)?/Registry" component={EzdRegistriesList} />
      <Route
        path="/(.*,)?e,ezd-registries-list.html"
        component={EzdRegistriesList}
      />
      <Route
        path="/(.*)?/ContactForm/(get|show)/id,:id.html"
        component={ContactForm}
      />
      <Route path="/(.*,)?e,contactForm,show.html" component={ContactForm} />
      <Route path="/(.*)?/Survey/(get|show)/id,:id.html" component={Survey} />
      <Route path="/(.*,)?s,:id,(.+).html" component={Survey} />
      <Route path="/(.*)?/Survey" component={SurveysList} />
      <Route path="/(.*,)?e,ankiety.html" component={SurveysList} />
      <Route
        path="/(.*)?/Calculator/alcoholSellFeeCalculator.html"
        component={AlcoholSellFeeCalculator}
      />
      <Route
        path="/(.*,)?e,kalkulator,alcoholSellFeeCalculator(.*)"
        component={AlcoholSellFeeCalculator}
      />
      <Route
        path="/(.*)?/Calculator/greenAreaAndTreeRemovalCalculator.html"
        component={GreenAreaAndTreeRemovalCalculator}
      />
      <Route
        path="/(.*,)?e,kalkulator,greenAreaAndTreeRemovalCalculator(.*)"
        component={GreenAreaAndTreeRemovalCalculator}
      />
      <Route
        path="/(.*)?/Calculator/occupationOfRoadwayCalculator.html"
        component={OccupationOfRoadwayCalculator}
      />
      <Route
        path="/(.*,)?e,kalkulator,occupationOfRoadwayCalculator(.*)"
        component={OccupationOfRoadwayCalculator}
      />
      <Route
        path="/(.*)?/Calculator/salaryCalculator.preview"
        component={SalaryCalculator}
      />
      <Route
        path="/(.*,)?e,kalkulator,salaryCalculator(.*)"
        component={SalaryCalculator}
      />
      <Route path="/(.*)?/Calculator" component={CalculatorsList} />
      <Route path="/(.*,)?e,kalkulator(.*)" component={CalculatorsList} />
      <Route path="/(.*)?/file-not-found" component={FileNotFound} />
      <Route path="/(.*)?/404" component={NoContentPage} />
      {userLogInEnabled && (
        <Switch>
          <PrivateRoute component={UserPanel} path="/(.*)?/UserPanel" />
          <PrivateRoute component={UserPanel} path="/(.*,)?e,userPanel.html" />
          <PublicRoute component={SignUp} path="/(.*)?/Login/register.html" />
          <PublicRoute component={SignUp} path="/(.*,)?e,login,register.html" />
          <PublicRoute
            component={RemindPassword}
            path="/(.*)?/Login/forgotPassword.html"
          />
          <PublicRoute
            component={RemindPassword}
            path="/(.*,)?e,login,forgotPassword.html"
          />
          <PublicRoute component={SignIn} path="/(.*)?/Login/index.html" />
          <PublicRoute component={SignIn} path="/(.*,)?e,login.html" />
          <PublicRoute component={NoContentPage} />
        </Switch>
      )}
      {showNoContentRoute && <Route component={NoContentPage} />}
    </Switch>
  );
};

Routes.propTypes = {
  homePath: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  showNoContentRoute: PropTypes.bool,
  userLogInEnabled: PropTypes.bool,
  startArticleId: PropTypes.string
};

const mapStateToProps = ({ context, language }) => ({
  friendlyUrl: context.contextFriendlyUrl,
  langCode: language.langCode,
  startUrl: context.startUrl,
  urlType: context.urlType,
  userLogInEnabled: context.userLogInEnabled,
  startArticleId: context.startArticleId
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withProps(
    ({
      startArticleId,
      location,
      friendlyUrl,
      startUrl = "",
      urlType,
      langCode,
      ...rest
    }) => {
      const separator = urlType === "full" ? "/" : ",";
      const isNotDefaultContext = friendlyUrl !== "default";
      const context = `${friendlyUrl === "default" ? "" : friendlyUrl}`;
      const lang = /pl_\w+/.test(langCode)
        ? ""
        : `${isNotDefaultContext ? separator : ""}${langCode.split("_")[0]}`;
      const extensionMatch = location.pathname.match(/\.\w+$/);
      const extension = extensionMatch !== null ? extensionMatch[0] : "";
      const hasSeparator = isNotDefaultContext && startUrl !== "";
      const redirectUrl = `/${context}${
        hasSeparator ? separator : ""
      }${startUrl}`;
      const homePath = `/${context}${lang}${extension}`;

      return {
        homePath,
        redirectUrl,
        showNoContentRoute: get(location, ["state", "preventNoContentPage"]),
        startArticleId: startArticleId
      };
    }
  )
);

export default enhance(Routes);
