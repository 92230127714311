import React from "react";
import { truncate } from "lodash";
import { NavLink } from "react-router-dom";

import SubMenu from "./SubMenu";
import PropTypes from "prop-types";
import { isSubMenu } from "../../../../utils/menu";
import { useMappedState } from "redux-react-hook";

const Redirection = ({ item }) => {
  const MaxNameLength = 60;
  const { menuDirIconEnabled, menuSelectedId } = useMappedState(
    mapStateToProps
  );
  const isSubMenuItem = isSubMenu(item);
  const isRedirectLink = !!item.redirectUrl;
  const isSelected = item.id === menuSelectedId;
  const showSubMenu = isSelected || isSubMenuItem;
  const showDirIcon = isSubMenuItem && menuDirIconEnabled;
  return (
    <>
      {isRedirectLink ? (
        <a
          href={item.link}
          target={item.target !== "_self" ? item.target : undefined}
          title={item && item.name && item.name.length > MaxNameLength ? item.name : undefined}
        >
          {showDirIcon && <span className="fa fa-folder-open-o" />}
          {truncate(item.name, {
            length: MaxNameLength,
            separator: " "
          })}
        </a>
      ) : (
        <NavLink
          to={`/${item.link}`}
          isActive={() => isSelected}
          activeClassName="active"
          target={item.target !== "_self" ? item.target : undefined}
          title={item && item.name && item.name.length > MaxNameLength ? item.name : undefined}
          replace
        >
          {showDirIcon && <span className="fa fa-folder-open-o" />}
          {truncate(item.name, {
            length: MaxNameLength,
            separator: " "
          })}
        </NavLink>
      )}
      {showSubMenu && <SubMenu items={item.children || []} />}
    </>
  );
};

Redirection.propTypes = {
  item: PropTypes.object.isRequired
};

const mapStateToProps = ({ context, menu }) => ({
  menuSelectedId: menu.selectedId,
  menuDirIconEnabled: context.menuDirIconEnabled
});

export { Redirection };
