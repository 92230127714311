/**
 * Created by fkurzawa on 26.01.18.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

const FooterEditable = ({footerHtml, footerHtmlMobile}) => (
    <Media query="(min-width: 992px)">
        {matches =>
            matches ?
                <div dangerouslySetInnerHTML={{__html: footerHtml}}/> :
                <div dangerouslySetInnerHTML={{__html: footerHtmlMobile}}/>
        }
    </Media>
);

FooterEditable.propTypes = {
    footerHtml: PropTypes.string,
    footerHtmlMobile: PropTypes.string
};

export default FooterEditable;