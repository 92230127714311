/**
 * Created by fkurzawa on 28.06.18.
 */
import {
    blueGrey,
    grey, red,
    white
} from 'material-colors';

const primaryTheme = {
    primaryColor: grey['500'],
    darkPrimaryColor: grey['700'],
    lightPrimaryColor: grey['200'],
    secondaryColor: grey['800'],
    dividerColor: grey['400'],
    defaultBackground: white,
    errorColor: grey['600'],
    highlightBackground: grey['100'],
    imageFilter: 'grayscale(100%)',
    focusOutline: '#ff8c1a solid 2px',

    text: {
        primaryColor: grey['800'],
        secondaryColor: grey['600'],
        lightColor: grey['200']
    },

    buttons: {
        primary: {
            color: grey['50'],
            background: grey['600'],
            hoverBackground: grey['800']
        },

        secondary: {
            color: grey['50'],
            background: grey['600'],
            hoverBackground: grey['800']
        },

        default: {
            color: grey['800'],
            background: grey['300'],
            hoverBackground: grey['400']
        },

        strongPrimary: {
            color: grey['50'],
            background: red['600'],
            hoverBackground: red['800']
        },

        strongSecondary: {
            color: grey['50'],
            background: blueGrey['600'],
            hoverBackground: blueGrey['800']
        }
    }
};

export default primaryTheme;
