/**
 * Created by fkurzawa on 02.02.18.
 */

const BIP_MODULES = {
    CALCULATOR: {
        id: 'calculator-0',
        type: 'others',
        name: 'Kalkulatory',
        fullUrl: 'Calculator/index.html',
        friendlyUrl: 'e,kalkulator.html'
    },
    CONTACT_FORM: {
        id: 'contact-form-0',
        type: 'contact_form',
        name: 'Formularz kontaktowy',
        fullUrl: 'ContactForm/show/id,:id.html',
        friendlyUrl: 'e,contactForm,show.html'
    },
    CONTENT_REGISTRY: {
        id: 'content-ezd-registries-list-0',
        type: 'context_registry',
        name: 'Rejestr zmian',
        fullUrl: 'ContentRegistry/getList.html',
        friendlyUrl: 'e,ContentRegistry,getList.html'
    },
    EDITORS_PAGE: {
        id: 'editors-page-0',
        type: 'others',
        name: 'Redakcja',
        fullUrl: 'Editors/index.html',
        friendlyUrl: 'e,editors.html'
    },
    DECLARATION_OF_AVAILABILITY: {
        id: 'declaration-of-availability-page-0',
        type: 'others',
        name: 'Deklaracja Dostępności',
        fullUrl: 'Declarations/index.html',
        friendlyUrl: 'e,deklaracja.html'
    },
    EZD_CASE_STATUS: {
        id: 'ezd-case-status-0',
        type: 'ezd_case_status',
        name: 'Status sprawy',
        fullUrl: 'EzdCaseStatus/get.html',
        friendlyUrl: 'e,ezdCaseStatus.html'
    },
    EZD_REGISTRY: {
        id: 'ezd-ezd-registries-list-0',
        type: 'ezd_registry_list',
        name: 'Rejestry',
        fullUrl: 'Registry/index.html',
        friendlyUrl: 'e,ezd-registries-list.html'
    },
    NEWSLETTER: {
        id: 'newsletter-0',
        type: 'others',
        name: 'Biuletyn',
        fullUrl: 'Newsletter/index.html',
        friendlyUrl: 'e,newsletter.html'
    },
    REMIND_PASSWORD: {
        id: 'remind-password-0',
        type: 'others',
        name: 'Przypomnij hasło',
        fullUrl: 'Login/forgotPassword.html',
        friendlyUrl: 'e,login,forgotPassword.html'
    },
    SITE_MAP: {
        id: 'site-map-0',
        type: 'others',
        name: 'Mapa strony',
        fullUrl: 'SiteMap/index.html',
        friendlyUrl: 'e,mapa-strony.html'
    },
    SIGN_IN: {
        id: 'sign-in-0',
        type: 'others',
        name: 'Login',
        fullUrl: 'Login/index.html',
        friendlyUrl: 'e,login.html'
    },
    SIGN_UP: {
        id: 'sign-up-0',
        type: 'others',
        name: 'Registration',
        fullUrl: 'Login/register.html',
        friendlyUrl: 'e,login,register.html'
    },
    SURVEY: {
        id: 'survey-0',
        type: 'survey_list',
        name: 'Ankiety',
        fullUrl: 'Survey/index.html',
        friendlyUrl: 'e,ankiety.html'
    },
    USER_PANEL: {
        id: 'user-panel-0',
        type: 'others',
        name: 'Panel użytkownika',
        fullUrl: 'UserPanel/index.html',
        friendlyUrl: 'e,userPanel.html'
    }
};

export { BIP_MODULES };
export default BIP_MODULES;