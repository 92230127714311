/**
 * Created by fkurzawa on 25.01.18.
 */
import { createActions, handleActions } from 'redux-actions';

const { fetchContextSucceeded } = createActions('FETCH_CONTEXT_SUCCEEDED');

const reducer = handleActions(
    {
        [fetchContextSucceeded]: (state, {payload: context = {}}) => context
    },
    {}
);

export { fetchContextSucceeded };
export default reducer;