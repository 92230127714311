import { connect } from "react-redux";
import { compose, mapProps } from "recompose";
import { omit } from "lodash";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "./Button";

const LinkButton = styled(Button.withComponent(Link))`
  && {
    appearance: inherit;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }
`;

const mapStateToProps = ({ accessibility }) => ({
  contrast: accessibility.isContrast === true ? "true" : "false"
});

const enhance = compose(
  connect(mapStateToProps),
  mapProps(props => omit(props, "dispatch"))
);

export default enhance(LinkButton);
