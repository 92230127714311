import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import Dropdown from "../../../../dropdown/Dropdown";
import { getInitialState } from "./../../../../../redux/utils/initial";
import { withLoader } from "./../../../../loader";

const LanguagesButton = ({
  availableLanguages,
  currentLangCode,
  onChangeLanguage
}) => (
  <Dropdown
    id="lang"
    title="Wersja językowa"
    name="lang"
    label={<span className="fa fa-flag" />}
  >
    {availableLanguages.map((lang, index) => {
      const [langCode, langTitle] = lang.langCode.split("_");
      const isCurrent = lang.langCode === currentLangCode;

      return (
        <button
          key={index}
          disabled={isCurrent}
          className={`dropdown-item${isCurrent ? " disabled" : ""}`}
          title={lang.langName}
          onClick={() => onChangeLanguage(lang)}
        >
          <span className={`flag-icon flag-icon-${langTitle.toLowerCase()}`} />{" "}
          {langCode.toUpperCase()}
        </button>
      );
    })}
  </Dropdown>
);

LanguagesButton.propTypes = {
  currentLangCode: PropTypes.string,
  availableLanguages: PropTypes.array,
  onChangeLanguage: PropTypes.func.isRequired
};

LanguagesButton.defaultProps = {
  availableLanguages: []
};

const mapStateToProps = ({ context, language }) => ({
  availableLanguages: context.availableLanguages,
  currentLangCode: language.langCode
});

const mapDispatchToProps = dispatch => ({
  getInitialState: path => dispatch(getInitialState(path))
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withLoader,
  withHandlers({
    onChangeLanguage: ({
      getInitialState,
      history,
      startLoader,
      stopLoader
    }) => async ({ startLink }) => {
      startLoader();
      await history.push(`/${startLink}`);
      await getInitialState(startLink);
      stopLoader();
    }
  })
);

export default enhance(LanguagesButton);
