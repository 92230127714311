import React from "react";
import styled from "styled-components";
import { withFormsy } from "formsy-react";

class SuggestionInputField extends React.Component {
  render() {
    const { inputProps } = this.props;

    return (
      <InputContainer>
        <input {...inputProps} accessKey="H"/>
      </InputContainer>
    );
  }
}

const InputContainer = styled.div`
  & > input {
    display: block;
    width: 100%;
    color: ${({ theme }) => theme.text.primaryColor};
    border: 1px solid ${({ theme }) => theme.dividerColor};
    font-size: 1em;
    padding: 0.25rem 0.5rem;

    &::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
      cursor: pointer;
      padding: 0.125em;
    }
    &:focus:not(:active) {
      outline: ${({ theme }) => theme.focusOutline};
    }
  }
`;

export default withFormsy(SuggestionInputField);
