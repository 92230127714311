import { BIP_MODULES } from "./../constants/bip_modules"
import { MENU_TYPES } from "./../constants/menu_types"

export function getEnabledMenuElements(langCode, context = {}) {
	let menuElements = [createSeparator("Inne")]

	if (context.contentRegistryEnabled) {
		menuElements.push(createMenuElement(langCode, context, BIP_MODULES.CONTENT_REGISTRY))
	}
	if (context.calculatorsEnabled) {
		menuElements.push(createMenuElement(langCode, context, BIP_MODULES.CALCULATOR))
	}
	if (context.caseStatusesEnabled) {
		menuElements.push(createMenuElement(langCode, context, BIP_MODULES.EZD_CASE_STATUS))
	}
	if (context.ezdRegistryEnabled) {
		const menuElement = createMenuElement(langCode, context, BIP_MODULES.EZD_REGISTRY)
		const { ezdRegistryMenuElements = [] } = context
		const redirectUrl =
			ezdRegistryMenuElements.length > 0 ? ezdRegistryMenuElements[0].link : null
		menuElements.push({
			...menuElement,
			link: redirectUrl !== null ? redirectUrl : menuElement.link,
			children: ezdRegistryMenuElements
		})
	}
	if (context.editorsPageEnabled) {
		menuElements.push(createMenuElement(langCode, context, BIP_MODULES.EDITORS_PAGE))
	}
	if (context.accessibilityPageEnabled){
		menuElements.push(createMenuElement(langCode, context, BIP_MODULES.DECLARATION_OF_AVAILABILITY))
	}
	return menuElements
}

export function createMenuElement(langCode, context = {}, module = {}) {
	const separator = context.urlType && context.urlType === "full" ? "/" : ","
	const isNotDefaultContext =
		context.contextFriendlyUrl && context.contextFriendlyUrl !== "default"
	const contextPathElement = `${isNotDefaultContext ? context.contextFriendlyUrl : ""}`
	const langPathElement = /pl_\w+/.test(langCode)
		? ""
		: `${context.urlType === "full" ? langCode : langCode.split("_")[0]}`
	const isNotDefaultLang = langPathElement !== ""
	const modulePathElement = context.urlType === "full" ? module.fullUrl : module.friendlyUrl
	const link = `${contextPathElement}${isNotDefaultContext ? separator : ""}${langPathElement}${
		isNotDefaultLang ? separator : ""
	}${modulePathElement}`

	return {
		id: module.id || null,
		targetId: module.id || null,
		link,
		name: module.name || "",
		parent: context.defaultMenuId || null,
		type: MENU_TYPES.MENU_ITEM,
		target: "_self",
		redirectUrl: null,
		children: null
	}
}

export function createSeparator(name) {
	return {
		name,
		type: MENU_TYPES.SEPARATOR
	}
}

export function isSeparator(menuItem) {
	return !!menuItem && menuItem.type === MENU_TYPES.SEPARATOR
}

export function isSubMenu(menuItem) {
	return !!menuItem && menuItem.type === MENU_TYPES.SUB_MENU
}

export const findMenuItemById = (menuItems = [], menuItemId) => {
	if (menuItemId) {
		for (let menuItem of menuItems) {
			if (menuItem.id === menuItemId) {
				return menuItem
			}
			if (menuItem.children && menuItem.children.length > 0) {
				let result = findMenuItemById(menuItem.children, menuItemId)
				if (result) {
					return result
				}
			}
		}
	}
}
