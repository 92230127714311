import React from "react";
import PropTypes from "prop-types";
import { Separator } from "./Separator";
import { MenuItem } from "./MenuItem";
import { isSeparator } from "./../../../../utils/menu";
import { SeparatorH2 } from "./SeparatorH2";

const MainMenuItems = props => {
  return props.items.map((item, index) => {
    if (isSeparator(item) && index === 0) {
      return <SeparatorH2 key={index} text={item.name} />;
    }

    if (isSeparator(item)) {
      return <Separator key={index} text={item.name} />;
    }

    return <MenuItem key={index} item={item} />;
  });
};

MainMenuItems.propTypes = {
  items: PropTypes.array
};

MainMenuItems.defaultProps = {
  items: []
};

export default MainMenuItems;
