import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

const VisitorsCounter = ({ counter }) => (
  <Container id="statistics_main">Liczba odwiedzin: {counter}</Container>
);

const Container = styled.section`
  color: ${({ theme }) => theme.asideMenuItemColor || theme.text.primaryColor};
  font-size: 0.75em;
  padding: 1rem;
`;

VisitorsCounter.propTypes = {
  visitorsCounter: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

VisitorsCounter.defaultProps = {
  visitorsCounter: 0
};

const mapStateToProps = ({ context }) => ({
  counter: context.visitorsCounter
});

export default connect(mapStateToProps)(VisitorsCounter);
