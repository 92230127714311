import React from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import styled from "styled-components";
import ApiConfig from "../../api/api.config";

const EmblemImage = ({ bipTitle, isContrast, logoAlt, logoSrc }) =>
  isContrast ? bipTitle : <Image src={logoSrc} alt={logoAlt} />;

const Image = styled.img`
  max-height: 80px;
  filter: ${({ theme }) => theme.imageFilter};
`;

const mapStateToProps = ({ accessibility, context }) => ({
  bipTitle: context.bipTitle,
  url: context.logoHref,
  logoFileId: context.logoFileId,
  isContrast: accessibility.isContrast
});

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ logoFileId, bipTitle }) => ({
    logoAlt: `Logo urzędu ${bipTitle}`,
    logoSrc: `${ApiConfig.getEntryPoint()}files/${logoFileId}`
  }))
);

export { EmblemImage, enhance };
export default enhance(EmblemImage);
