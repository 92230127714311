import axios from "axios";
import ApiConfig from "./api.config";

const apiUrl = `${ApiConfig.getEntryPoint()}url`;
const host = window.location.host;

export const getResource = url =>
  axios
    .get(`${apiUrl}/get-resource`, {
      params: {
        url
      }
    })
    .then(response => response.data);

export const getAllByUrl = url => {
  return axios
    .get(`${apiUrl}/get-all`, {
      params: {
        url,
        host
      }
    })
    .then(response => response.data);
};
