import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as logoUrl from "../../../assets/img/bip_logo.png";
import {Link} from "./Link";

const BipLogo = ({ isContrast }) => {
  const title = "Biuletyn Informacji Publicznej";
  return (
    <Link
      href="http://bip.gov.pl"
      id="logo"
      title="Biuletyn Informacji Publicznej"
      target="_blank"
      rel="noopener noreferrer"
    >
      {isContrast ? (
        title
      ) : (
        <Image alt="Logo Biuletyn Informacji Publicznej" src={logoUrl} />
      )}
    </Link>
  );
};

const Image = styled.img`
  filter: ${({ theme }) => theme.imageFilter};
`;

const mapStateToProps = ({ accessibility }) => ({
  isContrast: accessibility.isContrast
});

export default connect(mapStateToProps)(BipLogo);
