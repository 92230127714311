/**
 * Created by fkurzawa on 13.06.18.
 */
import { createActions, handleActions } from 'redux-actions';
import { getTranslation } from './../../api/language';

const { setTranslations, setLanguage } = createActions('SET_TRANSLATIONS', 'SET_LANGUAGE');

const reducer = handleActions(
    {
        [setTranslations]: (state, {payload}) => ({
            ...state,
            translations: payload
        }),
        [setLanguage]: (state, {payload}) => ({
            ...state,
            langCode: payload
        })
    },
    {
        translations: {}
    }
);

const changeLanguage = langCode => async dispatch => {
    dispatch(setLanguage(langCode));
    const translations = await getTranslation(langCode);
    const translationsDictionary = createTranslationsDictionary(translations);
    window.trans = translationsDictionary;
    dispatch(setTranslations(translationsDictionary));
};

const createTranslationsDictionary = (translations = []) => {
    let dictionary = {};
    translations.forEach(({key, translation}) => {
        dictionary[key.toLowerCase()] = translation;
    });
    return dictionary;
};

export {
    changeLanguage,
    setTranslations,
    setLanguage
};
export default reducer;