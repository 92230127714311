/**
 * Created by fkurzawa on 05.06.18.
 */

import { createActions, handleActions } from 'redux-actions';

const { setContrast, toggleContrast } = createActions('SET_CONTRAST', 'TOGGLE_CONTRAST');

const reducer = handleActions(
    {
        [setContrast]: (state, {payload: isContrast}) => ({...state, isContrast}),
        [toggleContrast]: (state) => ({...state, isContrast: !state.isContrast})
    },
    {
        isContrast: false
    }
);

const localStorageSaver = store => next => action => {
    next(action);
    if(action.type === setContrast.toString() || action.type === toggleContrast.toString()) {
        const { accessibility } = store.getState();
        window.localStorage.setItem('hc', accessibility.isContrast);
    }
};

export { setContrast, toggleContrast, localStorageSaver };
export default reducer;