/**
 * Created by fkurzawa on 01.03.18.
 */
import { createActions, handleActions } from 'redux-actions';

const { setBreadcrumbs, clearBreadcrumbs } = createActions('SET_BREADCRUMBS', 'CLEAR_BREADCRUMBS');

const reducer = handleActions(
    {
        [setBreadcrumbs]: (state, {payload = []}) => payload,
        [clearBreadcrumbs]: () => []
    },
    []
);

export { setBreadcrumbs, clearBreadcrumbs };
export default reducer;