import styled from "styled-components";

export const Link = styled.a`
  color: ${({ theme }) => theme.text.primaryColor};

  &:focus {
    outline: ${({ theme }) => theme.focusOutline};
  }

  &:hover {
    color: ${({ theme }) => theme.text.secondaryColor};
  }
`;
