import React from "react";
import { useMappedState } from "redux-react-hook";
import styled from "styled-components";
import * as wcagLogoUrl from "../../assets/img/wcag_logo.png";

const FooterAside = () => {
  const { corporateMarkEnabled, w3cInfoShow } = useMappedState(mapState);

  return (
    <Container>
      {w3cInfoShow && (
        <Image src={wcagLogoUrl} alt="WCAG 2.0 AA Logo" title="WCAG 2.0 AA" />
      )}
      {corporateMarkEnabled && (
        <Text>
          System jest częścią{" "}
          <Link href="http://www.madkom.pl/biuletyn-informacji-publicznej">
            SIDAS BIP
          </Link>
        </Text>
      )}
    </Container>
  );
};

const mapState = ({ context }) => ({
  corporateMarkEnabled: context.corporateMarkEnabled,
  w3cInfoShow: context.w3cInfoShow
});

const Container = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 0;
`;

const Image = styled.img`
  margin-bottom: 0.25rem;
  filter: ${({ theme }) => theme.imageFilter};
`;

const Text = styled.div`
  font-size: 0.75em;
  color: ${({ theme }) => theme.text.primaryColor};
`;

const Link = styled.a`
  color: ${({ theme }) => theme.text.secondaryColor};
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

export default FooterAside;
