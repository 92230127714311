import axios from 'axios';
import ApiConfig from './api.config';

export const getSearchSuggestion = query =>
    axios
        .get(`${ApiConfig.getEntryPoint()}search-suggestion`, {
            params: {
                query
            }
        })
        .then(response => response.data);