import React from "react";
import PropTypes from "prop-types";
import Button from "./../buttons/Button";

const Dropdown = props => (
  <div className="dropdown">
    <Button
      id={props.id}
      type="button"
      color="secondary"
      title={props.title}
      aria-label={props.title}
      className={`dropdown-toggle ${
        props.buttonClasses ? ` ${props.buttonClasses}` : ""
      }`}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {props.label}
    </Button>
    <div
      className={`dropdown-menu ${
        props.menuClasses ? ` ${props.menuClasses}` : ""
      }`}
      aria-labelledby={props.name}
    >
      {props.children}
    </div>
  </div>
);

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  buttonClasses: PropTypes.string,
  menuClasses: PropTypes.string,
  title: PropTypes.string
};

export default Dropdown;
