const setInitialAccessibility = () => {
  if (isLettersSpacing()) {
    document.querySelector("#root").classList.add("letters-spacing");
  }

  if (isLinesSpacing()) {
    document.querySelector("#root").classList.add("lines-spacing");
  }

  if (isParagraphsSpacing()) {
    document.querySelector("#root").classList.add("paragraphs-spacing");
  }

  if (isWordsSpacing()) {
    document.querySelector("#root").classList.add("words-spacing");
  }
};

const isContrast = () => isTrueInLocalStorage("hc");

const toggleParagraphSpacing = () =>
  toggleAccessibility("paragraphsSpacing", "paragraphs-spacing");

const isParagraphsSpacing = () => isTrueInLocalStorage("paragraphsSpacing");

const toggleLinesSpacing = () =>
  toggleAccessibility("linesSpacing", "lines-spacing");

const isLinesSpacing = () => isTrueInLocalStorage("linesSpacing");

const toggleWordsSpacing = () =>
  toggleAccessibility("wordsSpacing", "words-spacing");

const isWordsSpacing = () => isTrueInLocalStorage("wordsSpacing");

const toggleLettersSpacing = () =>
  toggleAccessibility("lettersSpacing", "letters-spacing");

const isLettersSpacing = () => isTrueInLocalStorage("lettersSpacing");

const toggleAccessibility = (localStorageKey, className) => {
  if (isTrueInLocalStorage(localStorageKey)) {
    localStorage.removeItem(localStorageKey);
    document.querySelector("#root").classList.remove(className);
  } else {
    localStorage.setItem(localStorageKey, "true");
    document.querySelector("#root").classList.add(className);
  }
};

const isTrueInLocalStorage = itemKey => {
  const item = localStorage.getItem(itemKey);
  return item && item === "true";
};

export {
  isContrast,
  isLettersSpacing,
  isLinesSpacing,
  isParagraphsSpacing,
  isWordsSpacing,
  setInitialAccessibility,
  toggleLettersSpacing,
  toggleLinesSpacing,
  toggleParagraphSpacing,
  toggleWordsSpacing
};
