import React from "react";
import { useMappedState } from "redux-react-hook";
import { MadkomLogo } from "./MadkomLogo";
import PksLogo from "./PksLogo";
import BipLogo from "./BipLogo";

const Logo = () => {
  const { siteTheme } = useMappedState(mapStateToProps);
  switch (siteTheme) {
    case "bif":
      return <MadkomLogo />;
    case "pks":
      return <PksLogo />;
    default:
      return <BipLogo />;
  }
};

const mapStateToProps = ({ context }) => ({
  siteTheme: context.siteTheme
});

export { Logo };
