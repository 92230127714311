import React, { Fragment } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import styled from "styled-components";
import { toggleContrast } from "../../../../../redux/modules/accessibility";
import {
  toggleLettersSpacing,
  toggleLinesSpacing,
  toggleParagraphSpacing,
  toggleWordsSpacing
} from "../../../../../utils/accessibility";
import { getFontSize, setFontSize } from "../../../../../utils/font-size";
import { FONT_SIZES } from "../../../../../constants/font_sizes";
import Dropdown from "../../../../dropdown/Dropdown";
import Button from "../../../../buttons/Button";

const AccessibilityMenu = () => {
  const { isContrast } = useMappedState(mapState);
  const dispatch = useDispatch();

  const increaseFontSize = () => {
    switch (getFontSize()) {
      case "0":
        setFontSize(FONT_SIZES.MEDIUM);
        break;
      case "1":
        setFontSize(FONT_SIZES.LARGE);
    }
  };

  const decreaseFontSize = () => {
    switch (getFontSize()) {
      case "1":
        setFontSize(FONT_SIZES.DEFAULT);
        break;
      case "2":
        setFontSize(FONT_SIZES.MEDIUM);
    }
  };

  return (
    <StyleWrapper>
      <Dropdown
        id="blind"
        title="Wersja dla niedowidzących"
        name="blind"
        label={<span className="fa fa-eye" />}
      >
        <Container>
          <Section>
            <HiddenAccessKeyButton accessKey="L" onClick={increaseFontSize} />
            <HiddenAccessKeyButton accessKey="S" onClick={decreaseFontSize} />
            <Button
              color="secondary"
              type="button"
              accessKey="J"
              onClick={() => setFontSize(FONT_SIZES.DEFAULT)}
            >
              A
            </Button>
            <Button
              color="secondary"
              type="button"
              onClick={() => setFontSize(FONT_SIZES.MEDIUM)}
            >
              A +
            </Button>
            <Button
              color="secondary"
              type="button"
              onClick={() => setFontSize(FONT_SIZES.LARGE)}
            >
              A ++
            </Button>
          </Section>
          <Section>
            <Button
              aria-label="Odstęp między akapitami"
              title="Odstęp między akapitami"
              color="secondary"
              type="button"
              onClick={toggleParagraphSpacing}
            >
              <span className="fa fa-arrows-v" />{" "}
              <span className="fa fa-paragraph" />
            </Button>
            <Button
              aria-label="Odstęp między wierszami"
              title="Odstęp między wierszami"
              color="secondary"
              type="button"
              onClick={toggleLinesSpacing}
            >
              <span className="fa fa-arrows-v" />{" "}
              <span className="fa fa-align-left" />
            </Button>
            <Button
              aria-label="Odstęp między słowami"
              title="Odstęp między słowami"
              color="secondary"
              type="button"
              onClick={toggleWordsSpacing}
            >
              <span className="fa fa-arrows-h" />{" "}
              <span className="fa fa-align-left" />
            </Button>
            <Button
              aria-label="Odstęp między literami"
              title="Odstęp między literami"
              color="secondary"
              type="button"
              onClick={toggleLettersSpacing}
            >
              <span className="fa fa-arrows-h" /> A
            </Button>
          </Section>
          <Section>
            <Button
              color="secondary"
              type="button"
              onClick={() => dispatch(toggleContrast())}
            >
              {isContrast ? (
                <Fragment>
                  <span
                    className="fa fa-eye-slash"
                    accessKey={isContrast ? "K" : ""}
                  />{" "}
                  Wersja standardowa
                </Fragment>
              ) : (
                <Fragment>
                  <span
                    className="fa fa-eye"
                    accessKey={isContrast ? "" : "K"}
                  />{" "}
                  Wersja kontrastowa
                </Fragment>
              )}
            </Button>
          </Section>
        </Container>
      </Dropdown>
    </StyleWrapper>
  );
};

const mapState = ({ accessibility }) => ({
  isContrast: accessibility.isContrast
});

const HiddenAccessKeyButton = styled.span`
  display: none;
`;

const StyleWrapper = styled.div`
  .dropdown-menu {
    background: ${({ theme }) => theme.defaultBackground};
    border-color: ${({ theme }) => theme.dividerColor};
  }
`;

const Container = styled.div`
  padding: 0.75rem 1rem;
`;

const Section = styled.section`
  display: flex;
  justify-content: space-around;

  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
    margin-bottom: 1rem;
  }

  & > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

export default AccessibilityMenu;
