import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Container from "../container";
import Emblem from "./Emblem";
import { HeaderEditable } from "./HeaderEditable";
import { Logo } from "./Logo";

const AppHeader = ({ reverseOrder, showEmblem, isBifTheme }) => {
  return (
    <HeaderContainer isBifTheme={isBifTheme}>
      <Content id="header" reverseOrder={reverseOrder} isBifTheme={isBifTheme}>
        <Logo />
        <HeaderEditableContainer id="slogan" isBifTheme={isBifTheme}>
          <HeaderEditable />
        </HeaderEditableContainer>
        {showEmblem && !isBifTheme && <Emblem />}
      </Content>
    </HeaderContainer>
  );
};

AppHeader.propTypes = {
  reverseOrder: PropTypes.bool,
  showEmblem: PropTypes.bool,
  isBifTheme: PropTypes.bool
};

const Content = styled(Container.withComponent("header"))`
  display: flex;
  flex-direction: ${({ reverseOrder }) =>
    reverseOrder ? "column-reverse" : "column"};
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.headerBackground};
  padding: 0.75rem 1rem 0 1rem;
  ${({ isBifTheme, theme }) =>
    !isBifTheme &&
    `
    padding: 0.75rem 1rem;
    border-bottom: 3px solid ${
      theme.layoutDividerColor ? theme.layoutDividerColor : theme.secondaryColor
    };
    margin-bottom: 1px;
`};
  margin-bottom: 1px;

  @media print {
    display: none;
  }

  @media (max-width: 767px) {
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @media (min-width: 768px) {
    flex-direction: ${({ reverseOrder }) =>
      reverseOrder ? "row-reverse" : "row"};
  }
`;

const HeaderContainer = styled.div`
  ${({ isBifTheme, theme }) =>
    isBifTheme &&
    `
    border-bottom: 3px solid ${
      theme.layoutDividerColor ? theme.layoutDividerColor : theme.secondaryColor
    };
    margin-bottom: 1px;
`};
`;

const HeaderEditableContainer = styled.div`
  ${({ isBifTheme }) => !isBifTheme && `flex-grow: 1 `};
  display: flex;
  justify-content: center;
`;

const mapStateToProps = ({ context }) => ({
  isPks: context.siteTheme === "pks",
  reverseOrder: context.siteTheme === "szamotuly",
  showEmblem: context.logoFileId !== null,
  isBifTheme: context.siteTheme === "bif"
});

const enhancedAppHeader = connect(mapStateToProps)(AppHeader);

export { enhancedAppHeader as AppHeader };
