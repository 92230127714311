import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import {
    addLoader,
    removeLoader
} from './../../redux/modules/loader';

const withLoader = WrappedComponent => {
    const loaderId = uuid();

    class WithLoader extends React.Component {
        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    const mapDispatchToProps = dispatch => ({
        startLoader: () => dispatch(addLoader(loaderId)),
        stopLoader: () => dispatch(removeLoader(loaderId))
    });

    return connect(undefined, mapDispatchToProps)(WithLoader);
};

export default withLoader;