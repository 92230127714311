/**
 * Created by fkurzawa on 30.01.18.
 */
export function getElementsOfUrlPath(path = '') {
    const url = trimUrlPath(path);
    const urlType = getUrlType(url);
    switch(urlType) {
        case URL_TYPES.FRIENDLY:
            return url.split(',');
        case URL_TYPES.FULL:
            return url.split('/');
        case URL_TYPES.SHORT:
            return [url];
        default:
            return [];
    }
}

export function getUrlType(path) {
    const url = trimUrlPath(path);
    if (url.indexOf('/') !== -1) {
        return URL_TYPES.FULL;
    } else if (url.indexOf(',') !== -1) {
        return URL_TYPES.FRIENDLY;
    }
    const shortUrlRegExp = new RegExp(`^\\d+(\\.(${AVAILABLE_FORMATS.join('|')}))?$`);
    return shortUrlRegExp.test(url) ? URL_TYPES.SHORT : URL_TYPES.FULL;
}

export function trimUrlPath(path) {
    return path[0] === '/' ? path.substr(1) : path;
}

export const URL_TYPES = {
    FRIENDLY: 'friendly',
    FULL: 'full',
    SHORT: 'short'
};

export const AVAILABLE_FORMATS = ['html', 'xml', 'json', 'jhtml', 'wsdl', 'pdf', 'preview'];
