import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import LatestArticles from "./LatestArticles";
import MainMenuItems from "./MainMenuItems";
import { Separator } from "./Separator";
import { getLatestArticles } from "./../../../../api/articles";

const Menu = ({
  items,
  additionalItems,
  lastArticlesEnabled,
  latestArticles,
  collapsedMobile,
  toggleCollapsedMobile
}) => (
  <MenuContainer>
    <DefaultList>
      <MainMenuItems items={[...items, ...additionalItems]} />
      {lastArticlesEnabled && <LatestArticles articles={latestArticles} />}
    </DefaultList>

    <MobileList>
      {
        items.length > 0 && <Separator text={{ ...items[0] }.name} onClick={toggleCollapsedMobile} />
      }
      {!collapsedMobile && (
        <React.Fragment>
          <MainMenuItems items={[...items, ...additionalItems].slice(1)} />
          {lastArticlesEnabled && <LatestArticles articles={latestArticles} />}
        </React.Fragment>
      )}
    </MobileList>
  </MenuContainer>
);

Menu.propTypes = {
  friendlyUrl: PropTypes.string,
  items: PropTypes.array,
  additionalItems: PropTypes.array,
  lastArticlesEnabled: PropTypes.bool,
  latestArticles: PropTypes.array,
  collapsedMobile: PropTypes.bool,
  toggleCollapsedMobile: PropTypes.func
};

Menu.defaultProps = {
  items: [],
  additionalItems: [],
  latestArticles: [],
  toggleCollapsedMobile: () => {}
};

const MenuContainer = styled.nav`
  font-size: 0.875em;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const DefaultList = styled(List)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`;

const MobileList = styled(List)`
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const mapStateToProps = ({ context, menu }) => ({
  friendlyUrl: context.contextFriendlyUrl,
  lastArticlesEnabled: context.lastArticlesEnabled,
  items: menu.items,
  additionalItems: menu.additionalItems
});

const enhance = compose(
  connect(mapStateToProps),
  withState("latestArticles", "setLatestArticles", []),
  withState("collapsedMobile", "setCollapsedMobile", false),
  withHandlers({
    toggleCollapsedMobile: ({ collapsedMobile, setCollapsedMobile }) => () => {
      setCollapsedMobile(!collapsedMobile);
    }
  }),
  lifecycle({
    async componentDidMount() {
      const {
        friendlyUrl,
        lastArticlesEnabled,
        setLatestArticles
      } = this.props;
      if (lastArticlesEnabled) {
        const latestArticles = await getLatestArticles(friendlyUrl);
        setLatestArticles(latestArticles);
      }
    }
  })
);

export default enhance(Menu);
