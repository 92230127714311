import { connect } from "react-redux";
import { compose, mapProps } from "recompose";
import { omit } from "lodash";
import styled from "styled-components";
import Button from "./Button";
import { getColor, getHoverBackground, getHoverBorderColor } from "./utils";

const ExternalLinkButton = styled(Button.withComponent("a"))`
  &&& {
    appearance: inherit;
    color: ${getColor};
    display: inline-block;

    &:hover:not(:disabled) {
      background: ${getHoverBackground};
      border-color: ${getHoverBorderColor};
      color: ${getColor};
      text-decoration: none;
    }
  }
`;

const mapStateToProps = ({ accessibility }) => ({
  contrast: accessibility.isContrast === true ? "true" : "false"
});

const enhance = compose(
  connect(mapStateToProps),
  mapProps(props => omit(props, "dispatch"))
);

export default enhance(ExternalLinkButton);
