import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import styled from 'styled-components';
import * as logoUrl from '../../../assets/img/pks_logo.png';

const PksLogo = ({ alt, isContrast, src, title }) => (
    isContrast ?
        title :
        <Image
            alt={alt}
            src={src}
            title={title} />
);

PksLogo.propTypes = {
    alt: PropTypes.string,
    isContrast: PropTypes.bool,
    src: PropTypes.string,
    title: PropTypes.string
}

const Image = styled.img`
    filter: ${({ theme }) => theme.imageFilter};
`;

const mapStateToProps = ({ accessibility }) => ({
    isContrast: accessibility.isContrast
});

const enhance = compose(
    connect(mapStateToProps),
    withProps({
        alt: "Logo Portalu Konsultacji Społecznych",
        src: logoUrl,
        title: "Portal Konsultacji Społecznych"
    })
);

export default enhance(PksLogo);
