import React, { useMemo, useState } from "react";
import styled from "styled-components";
import AutoSuggest from "react-autosuggest";
import { getSearchSuggestion } from "./../../../../../api/search-suggestion";
import SuggestionInputField from "./SuggestionInputField";

const SearchSuggestions = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");

  const onSuggestionsFetchRequested = async ({ value }) => {
    const { elements } = await getSearchSuggestion(value);
    setSuggestions(elements || []);
  };
  const onSuggestionsClearRequested = () => setSuggestions([]);

  const inputProps = useMemo(
    () => ({
      id: "skip-link-search",
      name: "s",
      onChange: (event, { newValue }) => setValue(newValue),
      placeholder: "Szukaj...",
      type: "search",
      "aria-label": "Wyszukiwarka główna",
      value
    }),
    [value]
  );

  return (
    <StyledSuggestionsContainer>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={Suggestion}
        renderInputComponent={InputComponent}
        inputProps={inputProps}
        ref={node => {
          //[84903] Attribute role cause that NVDA rider was reading "Lista rozwijana" and that's why it has been removed
          node && node.suggestionsContainer.previousElementSibling.offsetParent.removeAttribute(
                  "role"
              );
        }}
      />
    </StyledSuggestionsContainer>
  );
};

const Suggestion = suggestion => suggestion;

const InputComponent = inputProps => {
  const { value } = inputProps;

  return (
    <SuggestionInputField
      name={inputProps.name}
      validations={{
        minLength: 3
      }}
      required
      inputProps={inputProps}
      value={value}
    />
  );
};

const StyledSuggestionsContainer = styled.div`
  width: 100%;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    color: ${({ theme }) => theme.text.primaryColor};
    background: ${({ theme }) => theme.defaultBackground};
  }

  .react-autosuggest__suggestions-container {
    display: none;
    position: absolute;
    z-index: 2;
    border: 1px solid ${({ theme }) => theme.dividerColor};
    width: 100%;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: ${({ theme }) => theme.text.primaryColor};
    background: ${({ theme }) => theme.defaultBackground};
    font-size: 0.875em;

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background: ${({ theme }) => theme.lightPrimaryColor};
  }
`;

export default SearchSuggestions;
