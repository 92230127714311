/**
 * Created by fkurzawa on 11.06.18.
 */
import styled from 'styled-components';

const Container = styled.div`
    margin: auto;
    width: auto; // tablets and phones (landscape and portrait)
    padding: 0 1rem;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1440px) { // medium desktop
        max-width: 1400px;
    }

    @media (min-width: 1680px) { // big desktop
        max-width: 1600px;
    }
`;

export default Container;