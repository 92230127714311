import React from "react";
import styled from "styled-components";
import Formsy from "formsy-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { compose, withHandlers } from "recompose";
import SearchSuggestions from "./components/SearchSuggestions";
import { ButtonWithCustomFocus } from "../../../buttons";

const SearchBar = ({ onSearch }) => (
  <StyledFormsy onValidSubmit={onSearch}>
    <SearchFieldGroup>
      <SearchSuggestions />
      <ButtonWithCustomFocus type="submit" color="primary" aria-label="Szukaj" title="Szukaj">
        <span className="fa fa-search" />
      </ButtonWithCustomFocus>
    </SearchFieldGroup>
  </StyledFormsy>
);

const SearchFieldGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledFormsy = styled(Formsy)`
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 992px) {
    width: 40%;
  }

  @media screen and (min-width: 1200px) {
    width: 30%;
  }
`;

const mapStateToProps = ({ context }) => ({
  searchUrl: context.searchUrl
});

const enhance = compose(
  connect(mapStateToProps),
  withRouter,
  withHandlers({
    onSearch: ({ history, searchUrl }) => model => {
      history.push(`/${searchUrl}?${queryString.stringify(model)}`, {
        reload: true
      });
    }
  })
);

export default enhance(SearchBar);
