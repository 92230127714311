import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, withHandlers } from "recompose";
import { Button, ExternalLinkButton, LinkButton } from "./../buttons";
import Translate from "./../translate";
import BIP_MODULES from "../../constants/bip_modules";

const FooterContent = ({ links, onScrollTop }) => {
  return (
    <Container>
      <HiddenAccessKeyButton accessKey="T" onClick={onScrollTop} />
      <LinksList>
        {links.map((link, index) => (
          <LinkListItem key={index}>
            {isExternalLink(link.link) ? (
              <ExternalLinkButton
                href={link.link}
                target={link.target === "_self" ? null : link.target}
                flat={"true"}
              >
                {link.title}
              </ExternalLinkButton>
            ) : (
              <LinkButton
                to={`/${link.link}`}
                target={link.target === "_self" ? null : link.target}
                onClick={onScrollTop}
                flat={"true"}
                accessKey={
                  BIP_MODULES.SITE_MAP.friendlyUrl === link.link ? "M" : ""
                }
              >
                {link.title}
              </LinkButton>
            )}
          </LinkListItem>
        ))}
      </LinksList>
      <FooterGoUpButton
        accessKey="Q"
        flat={"true"}
        id="gototoplink"
        onClick={onScrollTop}
        type="button"
      >
        <Translate>Up</Translate> <span className="fa fa-arrow-up" />
      </FooterGoUpButton>
    </Container>
  );
};

FooterContent.propTypes = {
  links: PropTypes.array,
  onScrollTop: PropTypes.func
};

FooterContent.defaultProps = {
  links: [],
  onScrollTop: () => {}
};

const isExternalLink = url => /^https?.*/.test(url);

const HiddenAccessKeyButton = styled.span`
  display: none;
`;

const Container = styled.nav`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
`;

const LinksList = styled.ul`
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LinkListItem = styled.li`
  &:not(:last-child) {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  & a {
    color: ${({ theme }) =>
      theme.footerLinkColor ? theme.footerLinkColor : ""};

    &:hover:not(:disabled) {
      border-color: ${({ theme }) =>
        theme.footerLinkColor ? theme.footerLinkColor : ""};
    }
  }
`;

const FooterGoUpButton = styled(Button)`
  && {
    color: ${({ theme }) =>
      theme.footerLinkColor ? theme.footerLinkColor : ""};

    &:hover:not(:disabled) {
      border-color: ${({ theme }) =>
        theme.footerLinkColor ? theme.footerLinkColor : ""};
    }
  }
`;

const enhanced = compose(
  withHandlers({
    onScrollTop: () => () => {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    }
  })
);

export default enhanced(FooterContent);
