import React from 'react';
import styled from 'styled-components';

const Loader = ({ pastDelay }) => pastDelay ? <Spinner /> : null;

const Spinner = styled.span.attrs({ className: "fa fa-spinner fa-spin" })`
    && {
        color: ${({ theme }) => theme.text.primaryColor};
        font-size: 2em;
        padding: 1rem .5rem;
    }
`;

export default Loader;