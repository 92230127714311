import PropTypes from "prop-types";
import React from "react";
import Headroom from "react-headroom";
import Media from "react-media";
import { connect } from "react-redux";
import styled from "styled-components";
import SearchBar from "./components/search-bar/SearchBar";
import Tools from "./components/tools/Tools";
import Authentication from "./components/authentication/Authentication";

const Toolbar = ({ authenticationEnabled }) => (
  <Media query="(min-width: 768px)">
    {matches =>
      matches ? (
        <StickyContainer>
          <ToolbarContainer id="top-bar">
            <Content>
              <Tools />
              <SearchBar />
            </Content>
            {authenticationEnabled && <Authentication />}
          </ToolbarContainer>
        </StickyContainer>
      ) : (
        <Headroom style={{ zIndex: "100" }}>
          <MobileToolbarContainer id="top-bar">
            <Content>
              <Tools />
              {authenticationEnabled && <Authentication />}
            </Content>
            <SearchBar />
          </MobileToolbarContainer>
        </Headroom>
      )
    }
  </Media>
);

Toolbar.propTypes = {
  authenticationEnabled: PropTypes.bool
};

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const ToolbarContainer = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  padding: 1rem;
  background: ${({ theme }) =>
    theme.toolbarBackground || theme.lightPrimaryColor};

  @media print {
    display: none;
  }
`;

const MobileToolbarContainer = styled(ToolbarContainer)`
  align-items: stretch;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  height: auto;
  justify-content: space-between;

  &:not(:last-child) {
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
    }

    @media screen and (min-width: 768px) {
      margin-right: 1rem;
    }
  }
`;

const mapStateToProps = ({ context }) => ({
  authenticationEnabled: context.userLogInEnabled
});

export default connect(mapStateToProps)(Toolbar);
