/**
 * Created by fkurzawa on 13.06.18.
 */
import axios from 'axios';
import ApiConfig from './api.config';

const apiUrl = `${ApiConfig.getEntryPoint()}language`;

const getAvailableLanguages = () =>
    axios
        .get(`${apiUrl}/language`)
        .then(response => response.data);

const getTranslation = langCode =>
    axios
        .get(`${apiUrl}/${langCode}/translation`)
        .then(response => response.data);

export {
    getAvailableLanguages,
    getTranslation
}