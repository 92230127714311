import React from "react";
import Helmet from "react-helmet";
import Loadable from "react-loadable";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Switch, Route } from "react-router";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withProps, withState } from "recompose";
import { ThemeProvider } from "styled-components";
import "leaflet-defaulticon-compatibility";
import "bootstrap/dist/js/bootstrap.bundle";

import "./styles/index.css";
import themes from "./themes";

import { setContrast } from "./redux/modules/accessibility";
import { getInitialState } from "./redux/utils/initial";

import Layout from "./components/layout";
import Loader, { withLoader } from "./components/loader";
import Routes from "./components/routes";
import PrivacyPolicy from "./components/privacy-policy";

import { isContrast, setInitialAccessibility } from "./utils/accessibility";
import { setInitialFontSize } from "./utils/font-size";
import { prepareDeclarationOfAvailabilityUrl } from "./utils/prepareDeclarationOfAvailabilityUrl";

const Preview = Loadable({
  loader: () => import("./containers/preview"),
  loading: Loader
});

const App = ({
  title,
  lang,
  showLayout,
  theme,
  declarationOfAvailabilityUrl
}) => {

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Helmet defer={false}>
          <meta
            data-showdeclaration
            name="deklaracja-dostępności"
            content={
              declarationOfAvailabilityUrl ? declarationOfAvailabilityUrl : ""
            }
          />
          <title>{title}</title>
          <html lang={lang} />
        </Helmet>
        {showLayout && (
          <Switch>
            <Route path="/(.*)?/p,:previewHash.html" component={Preview} />
            <Layout declarationOfAvailabilityUrl={declarationOfAvailabilityUrl}>
              <Routes />
            </Layout>
          </Switch>
        )}
        <Loader />
        <PrivacyPolicy />
      </React.Fragment>
    </ThemeProvider>
  );
};

const mapStateToProps = ({
  context,
  accessibility,
  documentTitle,
  language
}) => ({
  bipTitle: context.bipTitle,
  contextFriendlyUrl: context.contextFriendlyUrl,
  googleAnalyticsTrackingId: context.googleAnalyticsTrackingId,
  isContrast: accessibility.isContrast,
  documentTitle,
  langCode: language.langCode,
  siteTheme: context.siteTheme,
  accessibilityPageEnabled: context.accessibilityPageEnabled
});

const mapDispatchToProps = dispatch => ({
  getInitialState: () => dispatch(getInitialState(window.location.pathname)),
  setContrast: isContrast => dispatch(setContrast(isContrast))
});

const enhance = compose(
  withRouter,
  withLoader,
  connect(mapStateToProps, mapDispatchToProps),
  withState("showLayout", "setShowLayout", false),
  withProps(
    ({
      siteTheme,
      isContrast,
      documentTitle,
      bipTitle,
      langCode = "",
      accessibilityPageEnabled,
      contextFriendlyUrl
    }) => {
      const theme = isContrast
        ? themes.contrast
        : themes[siteTheme] || themes.primary;
      const title = bipTitle
        ? `${documentTitle !== null ? `${documentTitle} | ` : ""}${bipTitle}`
        : "Biuletyn Informacji Publicznej";
      const [lang] = langCode.split("_");
      //const metaContent = (contextFriendlyUrl === 'default' && accessibilityPageEnabled) ?
      const declarationOfAvailabilityUrl = prepareDeclarationOfAvailabilityUrl(
        accessibilityPageEnabled,
        contextFriendlyUrl
      );
      return {
        theme,
        title,
        lang,
        declarationOfAvailabilityUrl
      };
    }
  ),
  lifecycle({
    async componentDidMount() {
      const {
        startLoader,
        stopLoader,
        getInitialState,
        setContrast,
        setShowLayout,
        location
      } = this.props;
      setInitialAccessibility();
      setContrast(isContrast());
      setInitialFontSize();
      startLoader();
      try {
        await getInitialState();

        if (this.props.googleAnalyticsTrackingId) {
          ReactGA.initialize(this.props.googleAnalyticsTrackingId);
          ReactGA.pageview(location.pathname + location.search);
        }
        setShowLayout(true);
      } finally {
        stopLoader();
      }
    },
    async componentDidUpdate(prevProps) {
      const { props } = this;
      if (
        props.googleAnalyticsTrackingId &&
        prevProps.location.pathname + prevProps.location.search !==
          props.location.pathname + props.location.search
      ) {
        ReactGA.pageview(props.location.pathname + props.location.search);
      }

      if (props.lang !== prevProps.lang) {
        const lang = props.lang === "en" ? "en-gb" : props.lang;
        await import(`moment/locale/${lang}`);
      }
    }
  })
);

export default enhance(App);
