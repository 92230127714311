import { FONT_SIZES } from "../constants/font_sizes";

export function setInitialFontSize() {
  const fontSize = getFontSize();
  setFontSize(fontSize);
}

export function setFontSize(fontSize) {
  clearFontSizes();
  localStorage.setItem("fontSize", fontSize);
  document.querySelector("#root").classList.add(`size-${fontSize}`);
}

export function getFontSize() {
  const fontSize = localStorage.getItem("fontSize");
  return fontSize || FONT_SIZES.DEFAULT;
}

function clearFontSizes() {
  const { classList } = document.querySelector("#root");
  const fontSizeClasses = Array.from(classList).filter(className =>
    /size-.+/.test(className)
  );
  fontSizeClasses.forEach(className => {
    classList.remove(className);
  });
}
