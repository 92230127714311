import axios from "axios";
import ApiConfig from "./api.config";

const apiUrl = `${ApiConfig.getEntryPoint()}contexts`;

export const getContext = (friendlyUrl = "default") => {
  return axios.get(`${apiUrl}/${friendlyUrl}`).then(response => response.data);
};

export const openRss = friendlyUrl => {
  window.open(getRssUrl(friendlyUrl), "_blank");
};

export const getRssUrl = friendlyUrl => {
  return `${apiUrl}/${friendlyUrl}/rss`;
};

export const getContentRegistries = (
  friendlyUrl,
  page,
  limit,
  timeperiod,
  from,
  to
) => {
  const offset = (page - 1) * limit;
  const params = {
    limit,
    offset,
    timeperiod,
    from,
    to
  };
  return axios
    .get(`${apiUrl}/${friendlyUrl}/registries`, {
      params
    })
    .then(response => response.data);
};

export const getOrganizationalUnits = friendlyUrl => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/organizational-units`)
    .then(response => response.data);
};

export const getGoogleMapConfiguration = friendlyUrl => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/google-map`)
    .then(response => response.data);
};

export const getArticles = (friendlyUrl, params) => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/articles`, {
      params
    })
    .then(response => response.data);
};

export const getEditors = friendlyUrl => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/editors`)
    .then(response => response.data);
};

export const getOrganizationalUnit = friendlyUrl => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/organizational-unit`)
    .then(response => response.data);
};

export const getAvailableNewsletters = friendlyUrl => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/newsletters`)
    .then(response => response.data);
};

export const postNewsletterSubscription = (friendlyUrl, email, formData) => {
  return axios.post(`${apiUrl}/${friendlyUrl}/subscribers/${email}`, formData);
};

export const postNewsletterSubscriptionConfirm = (friendlyUrl, hash) => {
  return axios.post(`${apiUrl}/${friendlyUrl}/subscriber-confirm/${hash}`);
};

export const getSiteMap = (friendlyUrl, langCode) => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/site-map/${langCode}`)
    .then(response => response.data);
};

export const getCaseStatus = (friendlyUrl, params) => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/ezd-case-status`, {
      params
    })
    .then(response => response.data);
};

export const getEzdCaseStatusStats$ = friendlyUrl => {
  return axios
      .get(`${apiUrl}/${friendlyUrl}/ezd-case-status-stats`)
      .then(response => response.data);
};

export const getEzdRegistries = (friendlyUrl, params) => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/ezd-registries`, {
      params
    })
    .then(response => response.data);
};

export const getEzdRegistry = (friendlyUrl, ezdRegistryId, params) => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/ezd-registries/${ezdRegistryId}`, {
      params
    })
    .then(response => response.data);
};

export const getSurveysList = friendlyUrl => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/surveys`)
    .then(response => response.data);
};

export const getSurvey = (friendlyUrl, surveyId) => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/surveys/${surveyId}`)
    .then(response => response.data);
};

export const getSurveyResults = (friendlyUrl, surveyId) => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/surveys/${surveyId}/completed`)
    .then(response => response.data);
};

export const postSurvey = (friendlyUrl, surveyId, formData) => {
  return axios.post(
    `${apiUrl}/${friendlyUrl}/surveys/${surveyId}/completed`,
    formData
  );
};

export const getDeclarationsAvailabilityContent = (friendlyUrl) => {
  return axios
      .get(`${apiUrl}/${friendlyUrl}/accessibility-declaration`)
      .then(response => response.data);
};