/**
 * Created by fkurzawa on 25.01.18.
 */
import axios from 'axios';
import ApiConfig from './api.config';

const apiUrl = `${ApiConfig.getEntryPoint()}menu`;

export const getMenu = menuId =>
    axios
        .get(`${apiUrl}/${menuId}`)
        .then(response => response.data);

export const getSubMenu = (menuId, targetId) =>
    axios
        .get(`${apiUrl}/${menuId}/submenu${targetId ? `/${targetId}` : ''}`)
        .then(response => response.data);

export const getMenuArticles = (menuId, limit = 10, offset = 0, sort, sort_dir, archived, tab, searchParams) =>
    axios
        .get(`${apiUrl}/${menuId}/articles`, {
            params: {
                limit,
                offset,
                sort,
                sort_dir,
                archived,
                tab,
                ...searchParams
            }
        })
        .then(response => response.data);

export const getRssUrl = (friendlyUrl = 'default') =>
    `${apiUrl}/${friendlyUrl}/rss`;

export const getXmlUrl = (friendlyUrl = 'default') =>
    `${apiUrl}/${friendlyUrl}/xml`;