/**
 * Created by fkurzawa on 03.07.18.
 */
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, withHandlers, withProps } from "recompose";
import styled from "styled-components";
import { Button, LinkButton } from "./../../../buttons";
import BIP_MODULES from "./../../../../constants/bip_modules";
import { signOut } from "./../../../../redux/modules/authentication";
import {LinkButtonWithCustomFocus} from "../../../buttons";

const Authentication = ({
  isAuthenticated,
  onSignOut,
  signInUrl,
  signUpUrl,
  userPanelUrl
}) => (
  <AuthenticationContainer>
    {isAuthenticated ? (
      <React.Fragment>
        <LinkButton
          aria-label="Panel użytkownika"
          color="secondary"
          title="Panel użytkownika"
          to={userPanelUrl}
          type="button"
        >
          <span className="fa fa-user-circle" />
        </LinkButton>
        <Button
          aria-label="Wyloguj"
          color="primary"
          onClick={onSignOut}
          title="Wyloguj"
          type="button"
        >
          <span className="fa fa-sign-out" />
        </Button>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <LinkButton
          to={signUpUrl}
          title="Rejestracja"
          aria-label="Rejestracja"
          color="secondary"
          type="button"
        >
          <span className="fa fa-user-plus" />
        </LinkButton>
        <LinkButtonWithCustomFocus
          to={signInUrl}
          title="Logowanie"
          aria-label="Logowanie"
          color="primary"
          type="button"
        >
          <span className="fa fa-sign-in" />
        </LinkButtonWithCustomFocus>
      </React.Fragment>
    )}
  </AuthenticationContainer>
);

Authentication.propTypes = {
  isAuthenticated: PropTypes.bool,
  onSignOut: PropTypes.func.isRequired,
  signInUrl: PropTypes.string.isRequired,
  signUpUrl: PropTypes.string.isRequired,
  userPanelUrl: PropTypes.string.isRequired
};

const AuthenticationContainer = styled.div`
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const mapStateToProps = ({ authentication, context }) => ({
  friendlyUrl: context.contextFriendlyUrl,
  isAuthenticated: authentication.isAuthenticated,
  urlType: context.urlType
});

const mapDispatchToProps = dispatch => ({
  onSignOut: () => dispatch(signOut())
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ friendlyUrl, urlType }) => {
    const isFullUrl = urlType === "full";
    const context =
      friendlyUrl !== "default" ? `${friendlyUrl}${isFullUrl ? "/" : ","}` : "";
    return {
      signInUrl: `/${context}${
        isFullUrl
          ? BIP_MODULES.SIGN_IN.fullUrl
          : BIP_MODULES.SIGN_IN.friendlyUrl
      }`,
      signUpUrl: `/${context}${
        isFullUrl
          ? BIP_MODULES.SIGN_UP.fullUrl
          : BIP_MODULES.SIGN_UP.friendlyUrl
      }`,
      userPanelUrl: `/${context}${
        isFullUrl
          ? BIP_MODULES.USER_PANEL.fullUrl
          : BIP_MODULES.USER_PANEL.friendlyUrl
      }`
    };
  }),
  withHandlers({
    onSignOut: ({ history, onSignOut, signInUrl }) => async () => {
      await onSignOut();
      history.push(signInUrl);
    }
  })
);

export default enhance(Authentication);
