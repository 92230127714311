/**
 * Created by fkurzawa on 05.02.18.
 */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Separator } from "./Separator";
import ListItem from "./ListItem";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";
import { Redirection } from "./Redirection";

const LatestArticles = ({ articles }) => {
  const { isBifTheme } = useMappedState(mapStateToProps);
  return (
    <React.Fragment>
      <Separator text="Aktualności" />
      {isBifTheme
        ? articles.map((article, index) => (
            <BifListItem key={index}>
              {/*<Link to={`/${article.link}`}>{article.title}</Link>*/}
              <Redirection item={{ ...article, name: article.title }} />
            </BifListItem>
          ))
        : articles.map((article, index) => (
            <ListItem key={index}>
              {/*<Link to={`/${article.link}`}>{article.title}</Link>*/}
              <Redirection item={{ ...article, name: article.title }} />
            </ListItem>
          ))}
    </React.Fragment>
  );
};

LatestArticles.propTypes = {
  articles: PropTypes.array
};

LatestArticles.defaultProps = {
  articles: []
};

const mapStateToProps = ({ context }) => ({
  isBifTheme: context.siteTheme === "bif"
});

const BifListItem = styled(ListItem)`
  margin-left: -100%;
  padding-left: 100%;
  border-bottom: none;
  a {
    border-bottom: none;
    border-left: 6px solid transparent;
    &.active {
      border-left-color: ${({ theme }) => theme.secondaryColor};
      border-right-color: transparent;
    }
  }
`;

export default LatestArticles;
