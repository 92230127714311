import React from "react";
import styled from "styled-components";
import * as logoUrl from "../../../assets/img/madkom_logo.png";
import { useMappedState } from "redux-react-hook";
import { Link } from "./Link";

const MadkomLogo = () => {
  const { isContrast } = useMappedState(mapStateToProps);
  const companyTitle = "Madkom";
  const bifTitle = "Biuletyn informacji firmowej";
  return (
    <MadkomLogoContainer>
      {!isContrast ? (
        <>
          <StyledBifTitle>BIF</StyledBifTitle>
          <Link
            href="https://madkom.pl/"
            id="logo"
            title="Madkom"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image alt="Madkom" src={logoUrl} />
          </Link>
        </>
      ) : (
        <>
          <div>{bifTitle}</div>
          <Link
            href="https://madkom.pl/"
            id="logo"
            title="Madkom"
            target="_blank"
            rel="noopener noreferrer"
          >
            {`logo ${companyTitle}`}
          </Link>
        </>
      )}
    </MadkomLogoContainer>
  );
};

const MadkomLogoContainer = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

const StyledBifTitle = styled.span`
  font-size: 3em;
  background-color: #ab0a11;
  color: #fff;
  padding: 0 10px;
  font-family: sans-serif;
  font-weight: 100;
  position: absolute;
`;

const Image = styled.img`
  filter: ${({ theme }) => theme.imageFilter};
  margin-left: 115px;
  margin-top: 20px;
`;

const mapStateToProps = ({ accessibility }) => ({
  isContrast: accessibility.isContrast
});

export { MadkomLogo };
