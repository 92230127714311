import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router';
import { compose, withProps } from 'recompose';
import BIP_MODULES from './../../../constants/bip_modules';

const PrivateRoute = ({ component: Component, isAuthenticated, redirectUrl, ...rest }) => (
    <Route {...rest} render={props =>
        isAuthenticated === true ?
            <Component {...props} /> :
            <Redirect to={{
                pathname: redirectUrl,
                state: {
                    from: props.location
                }
            }} />
    } />
);

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool,
    redirectUrl: PropTypes.string.isRequired
};

const mapStateToProps = ({ authentication, context }) => ({
    friendlyUrl: context.contextFriendlyUrl,
    isAuthenticated: authentication.isAuthenticated,
    urlType: context.urlType
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
    withProps(({ friendlyUrl, location, urlType }) => {
        const isFullUrl = urlType === 'full';
        const context = friendlyUrl !== 'default' ? `${friendlyUrl}${isFullUrl ? '/' : ','}` : '';
        const userPanelUrl = `/${context}${isFullUrl ? BIP_MODULES.SIGN_IN.fullUrl : BIP_MODULES.SIGN_IN.friendlyUrl}`;
        const redirectUrl = (location.state && location.state.redirectUrl) || userPanelUrl;
        return {
            redirectUrl
        }
    })
)

export default enhance(PrivateRoute);