/**
 * Created by fkurzawa on 04.06.18.
 */
import styled from "styled-components";

const ListItem = styled.li`
  background: ${({theme}) =>
    theme.asideMenuItemBackground || theme.lightPrimaryColor};

  .fa {
    margin-right: 0.5rem;
  }

  a {
    color: ${({theme}) =>
    theme.asideMenuItemColor || theme.text.primaryColor};
    border-bottom: 1px solid ${({theme}) => theme.dividerColor};
    display: block;

    padding: 10px 15px;
    border-right: 6px solid transparent;

    &:hover,
    &.active:hover {
      text-decoration: none;
      position: relative;
      z-index: 1;
      color: ${({theme}) => theme.secondaryColor};
      border-bottom-color: ${({theme}) => theme.secondaryColor};
    }

    &.active {
      color: ${({theme}) =>
    theme.asideMenuItemActiveColor || theme.text.primaryColor};
      border-right-color: ${({theme}) => theme.secondaryColor};
      background: ${({theme}) =>
    theme.asideMenuItemActiveBackground || theme.defaultBackground};
    }

    &:focus-within:not(:active) {
      outline: ${({ theme }) => theme.focusOutline};;
    }
  }
`;

export default ListItem;
